.countDown__cont {
  font-family: SKODANext, Tahoma, Helvetica, sans-serif;
  font-size: var(--countdown-font-size);
  color: #000;
  display: flex;
}

@media screen and (max-width: 1200px) {
  .countDown__cont {
    font-size: 10px;
  }
}

@media screen and (max-width: 786px) {
  .countDown__cont {
    font-size: 7px;
  }
}

.countDown__interval_cont {
  justify-content: space-around;
  width: auto;
  display: flex;
}

.countDown__interval_cont:nth-child(n+1):not(:last-child) {
  margin-right: 1em;
}

.countDown__interval_basic_cont {
  flex-direction: column;
  display: flex;
  position: relative;
}

.countDown__interval_basic_cont_description {
  color: #252525;
  text-shadow: .1em .1em .1em contrast(#252525);
  margin-top: .3em;
  margin-left: .3em;
  font-size: 1.2em;
  font-weight: bold;
  display: flex;
}

.countDown__digit_cont {
  -webkit-perspective: 3.2em;
  perspective: 3.2em;
  border-radius: .25rem;
  width: 1em;
  height: 1.6em;
  font-size: 5em;
  font-weight: bold;
  line-height: 1.6em;
  position: relative;
  box-shadow: .05em .05em .05em rgba(0, 0, 0, .2);
}

.countDown__digit_cont:nth-child(n+1):not(:last-child) {
  margin-right: .05em;
}

.countDown__digit_last_placeholder, .countDown__digit_new_placeholder {
  text-align: center;
  width: 100%;
  height: 50%;
  position: absolute;
  left: 0;
  overflow: hidden;
}

.countDown__digit_last_placeholder {
  background: #fff;
  border-radius: 0 0 .25rem .25rem;
  bottom: 0;
}

.countDown__digit_last_placeholder_inner {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  bottom: 50%;
}

.countDown__digit_new_placeholder {
  background: #f7f7f7;
  border-radius: .25rem .25rem 0 0;
  top: 0;
}

.countDown__digit_last_rotate, .countDown__digit_new_rotate {
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  border-radius: .25rem .25rem 0 0;
  justify-content: center;
  width: 100%;
  height: 50%;
  font-weight: bold;
  -webkit-animation-duration: .4s;
  animation-duration: .4s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  display: flex;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.countDown__digit_last_rotate:after, .countDown__digit_new_rotate:after {
  content: "";
  z-index: -1;
  border-bottom: .01em solid rgba(0, 0, 0, .1);
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.countDown__digit_last_rotate {
  background: #f7f7f7;
  -webkit-animation-name: countDown_flip_1;
  animation-name: countDown_flip_1;
}

.countDown__digit_new_rotate {
  background: #fff;
  -webkit-animation-name: countDown_flip_2;
  animation-name: countDown_flip_2;
}

.countDown__digit_new_rotated {
  width: 100%;
  height: 100%;
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

.countDown__digit_new_rotated_inner {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  bottom: 50%;
}

@-webkit-keyframes countDown_flip_1 {
  0% {
    z-index: 1;
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }

  100% {
    z-index: 0;
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
  }
}

@keyframes countDown_flip_1 {
  0% {
    z-index: 1;
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }

  100% {
    z-index: 0;
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
  }
}

@-webkit-keyframes countDown_flip_2 {
  0% {
    z-index: 0;
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }

  100% {
    z-index: 1;
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
  }
}

@keyframes countDown_flip_2 {
  0% {
    z-index: 0;
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }

  100% {
    z-index: 1;
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
  }
}

/*# sourceMappingURL=countdown.css.map */
